<template>
  <el-dialog :model-value="showDialog" :show-close="true" :close-on-click-modal="false" :close-on-press-escape="false"
    :append-to-body="true" center width="600px" @close="closeDialog">
    <template #title>
      <span v-if="info.ID === 0">新增用户</span>
      <span v-else>编辑用户</span>
    </template>
    <el-form ref="form" :model="info" label-position="right" label-width="100px">
      <el-form-item prop="UserName" label="用户名" :rules="{required: info.ID===0, message: '用户名不能为空', trigger: 'blur'}">
        <el-input v-model="item.UserName" name="UserName" type="text" placeholder="用户名" :disabled="info.ID>0" />
      </el-form-item>
      <el-form-item prop="Type" label="权限" :rules="{required: true, message: '权限不能为空', trigger: 'change'}">
        <el-select v-model="item.Type" placeholder="请选择">
          <el-option label="管理员" :value="1"></el-option>
          <el-option label="只读" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="Password" label="密码" :rules="{required: info.ID===0, message: '密码不能为空', trigger: 'blur'}">
        <el-input v-model="item.Password" name="Password" type="password" placeholder="密码" />
      </el-form-item>
      <div style="text-align:center;">
        <el-button :loading="loading" type="primary" @click="handleSave">保存</el-button>
        <el-button type="default" @click="closeDialog">取消</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
  import {
    upsertUser
  } from "@/api/userMgr"

  export default {
    props: {
      info: {
        type: Object,
        required: true
      },
      refreshParent: {
        type: Function,
        required: true
      },
      showDialog: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        loading: false,
        item: {}
      };
    },
    watch: {
      showDialog: {
        handler: function(val) {
          if (val) {
            if (this.$refs.form) this.$refs.form.resetFields();
            this.item = this.info
          }
        },
        immediate: true
      }
    },
    methods: {
      handleSave() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.loading = true;
            console.log(this.info);
            upsertUser(
                this.info.ID,
                this.info.UserName,
                this.info.Password,
                this.info.Type
              )
              .then(res => {
                this.loading = false;
                if (res.success) {
                  this.$message({
                    message: "保存成功",
                    type: "success"
                  });
                  this.$emit("update:showDialog", false);
                  this.refreshParent();
                }
              })
              .catch(err => {
                console.log(err);
                this.loading = false;
              });
          }
        });
      },
      closeDialog() {
        this.$emit("update:showDialog", false)
      }
    }
  };
</script>
